@import '../../Style/Variables.css';

.modelTileContainer {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: var(--smallBorderRadius);
    height: 240px;
    gap: 10px;
    overflow: hidden;
}

.modelImg {
    width: 240px;
    transition: transform 0.2s;
}

.modelTileContainer:hover {
    .modelImg {
        transform: scale(1.1);
    }
}