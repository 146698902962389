@import '../../Style/Variables.css';

h1, h2, h3, h4 {
    margin: 0;
}

p {
    margin: 0;
}

.tilePadding {
    padding: 14px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: box-shadow 0.2s;
}

.productTileContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: var(--productTileWidth);
    height: var(--productTileHeight);
    border-radius: var(--mediumBorderRadius);;
    overflow: hidden;
    background-color: white;
    margin: var(--productTileMargin);
    transition: transform 0.1s ease-in-out;
    .productImgContainer {
        width: 100%;
        height: var(--productTileWidth);
        background-color: white;
        overflow: hidden;
    }
    .productImg {
        width: 100%;
        height: var(--productTileWidth);
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
    }
    .tileTitle {
        width: 100%;
        margin-bottom: 6px;
    }
    .tileProductName {
        font-weight: 300;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 32px;
        transition: max-height 0.4s ease-out;
        color: var(--textColor);
    }
    .tileDescription {
        width: 100%;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--grayedText);
    }
    .tileInfo {
        align-items: center;
        justify-content: space-between;
        background-color: white;
    }
}

.productTileContainer:hover {
    .productImg {
        transform: scale(var(--hoverScaleLarge));
    }
}

.smallProductTileContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: var(--smallBorderRadius);
    overflow: hidden;
    color: var(--textColor);
    .smallTileImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        object-fit: cover;
        transition: transform 0.2s ease;
    }
}

.smallProductTileContainer:hover {
    .smallTileImg {
        transform: scale(var(--hoverScaleLarge));
    }
}

@media (max-width: 500px) {
    .productTileContainer {
        /* border-radius: 0; */
        /* width: var(--productTileWidthMobile); */
        width: 100%;
        height: fit-content;
        /* height: var(--productTileHeightMobile); */
        margin: var(--productTileMarginMobile);
        /* margin-bottom: 20px; */
        flex-direction: row;
        align-items: center;
        .productImgContainer {
            height: 100px;
            width: 100px;
        }
        .productImg {
            height: 100px;
            width: 100px;
        }
        .tilePadding {
            width: 70%;
        }
        .tileTitle {
            height: auto;
            /* max-height: 108px; */
            overflow-x: clip;
            white-space: wrap;
            text-overflow: ellipsis;
        }
        .tileDescription {
            display: flex;
        }
        .tileProductName {
            max-height: 200px !important;
            white-space: normal !important;
            font-size: 16px;
        }
        .discountHighlight {
            font-size: 16px;
        }
    }

}
