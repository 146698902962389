@import '../Style/Variables.css';

.tabSelector {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--lightGray);
    mark {
        background-color: var(--secondColor);
        color: var(--secondContent);
    }
}

.tabButton {
    padding: 10px 20px 6px;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    position: relative;
}

.tabButton:not(:last-child) {
    margin-right: 10px;
}

.tabButton.active {
    border-bottom: 2px solid var(--mainColor);
}

.tabButton.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background: var(--mainColor);
}

@media (max-width: 640px) {
    .tabButton {
        padding: 10px 10px 6px;
        h2 {
            font-size: 16px;
        }
    }
    .tabButton:not(:last-child) {
        margin-right: 4px;
    }
}