@import '../Style/Variables.css';

.dialogBackground {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.dialogContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    padding: 20px !important;
    transform: translateY(-10vh)
}

.dialogContainer select {
    width: 360px;
}


.dialogImg {
    width: 64px;
    height: 64px;
}

.dialogCloseButton {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    color: var(--textColor)
}

@media (max-width: 360px) {
    .dialogContainer select {
        width: 90vw;
    }
}