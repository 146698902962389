@import '../../Style/Variables.css';

h1, h2, h3, h4 {
    margin: 0;
}

p {
    margin: 0;
}

.buttonColorSelectSeller {
    background-color: var(--veryLightGray);
    color: var(--textColor);
    transition: background-color 0.2s;
}

.buttonColorSelectSeller:hover {
    color: var(--mainContent);
    background-color: var(--mainColorHover);
}

.sellerTileContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-radius: var(--mediumBorderRadius);
    border: 1px solid var(--lightGray);
}

.sellerSelect {
    border: 1px solid var(--veryLightGray);
}

.sellerSelect:hover {
    border: 1px solid var(--mainColor);
    border-radius: var(--mediumBorderRadius);
    .buttonColorSelectSeller {
        color: var(--mainContent);
        background-color: var(--mainColorHover);
        transition: background-color 0.2s;
    }
}

.selectedSeller {
    border: 1px solid var(--mainColor) !important;
    border-radius: var(--smallBorderRadius) !important;
    /* background-color: var(--veryFadedMainColor); */
    background-color: var(--lightGray);

}

.selectedSeller:hover {
    border: 1px solid var(--mainColor);
    border-radius: var(--smallBorderRadius);
    /* background-color: var(--veryFadedMainColor); */
    background-color: var(--lightGray);
    .buttonColorSelectSeller {
        color: var(--mainContent);
        background-color: var(--mainColorHover);
        transition: background-color 0.2s;
    }
}

.sellerLogo {
    /* width: 56px;
    height: 56px; */
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: var(--smallBorderRadius);
}

.sellerLogoMobile {
    display: none;
}

.narrowSellerTile {
    max-height: 40px !important;
    .sellerLogo {
        width: 40px;
        height: 40px;
    }
}

.sellerInfo {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.tileSellerName {
    font-weight: 300;
    font-size: 14px;
    color: var(--textColor);
    width: fit-content;
}

.tileDescription {
    width: 100%;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--grayedText);
}

.sellerTileInfo {
    align-items: center;
    justify-content: space-between;
}

.sellerOriginalPrice {
    text-decoration: line-through;
    color: var(--grayedText);
    line-height: 1.2;
}

.sellerMainPrice {
    font-weight: 700;
    color: var(--mainColor);
    line-height: 1.4;
    font-size: 24px;
}

.sellerButtonsColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sellerStarsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transform: translateY(-2px);
}

@media (max-width: 600px) {
    .sellerLogo {
        display: none;
    }
    .sellerLogoMobile {
        display: flex;
        width: 32px;
        height: 32px;
    }
    .tileSellerName {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .sellerStarsRow {
        transform: translateY(1px);
    }
    .sellerSelect {
        border: 1px solid var(--veryLightGray)
    }
    .sellerMainPrice {
        font-weight: 700;
        color: var(--mainColor);
        line-height: 1.4;
        font-size: 20px;
    }
}