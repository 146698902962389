@import '../../Style/Variables.css';

.autoplay-progress {
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 10;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--secondColor);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 2px;
  stroke: var(--secondColor);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.bannerContainer{
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 400px;
  max-width: 1280px;
  margin: auto;
  gap: 20px;
  /* height: 70vh;
  min-height: 400px;
  max-height: 800px; */
  color: white;
  form {
    display: flex;
    width: 100%;
  }
  input, select {
    text-align: center;
  }
  .swiper-pagination-bullet {
    background: #FFF !important;
  }
}

.bannerRowBrands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 960px;
  /* gap: 10px; */
  .headerFilterOption {
    padding: 6px;
  }
  .headerFilterOption:hover {
    background-color: rgba(255, 255, 255, 0.1)
  }
  .headerFilterBrandLogo {
    width: 80px;
    height: 80px;
  }
}

.defaultBannerBG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 440px;
  width: 100%;
  gap: 20px;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  form {
    display: flex;
    width: 100%;
  }
  input, select {
    text-align: center;
  }
  .swiper-pagination-bullet {
    background: #FFF !important;
  }
  .aboutUsBannerSpan {
    text-align: center;
    font-size: 18px;
  }
  .brandLogosBanner {
    width: 64px;
  }
  .brandLogosBannerWide {
    width: 120px;
  }
}

.bannerHighlightText {
  max-width: 600px;
  text-align: center;
  font-size: 21px;
  padding-inline: 20px;
}

.bannerHighlightTextLarge {
  max-width: 600px;
  text-align: center;
  font-size: 28px;
  padding-inline: 20px;
}

.titleContainer {
  max-width: 960px;
  text-align: center;
  h1 {
    font-size: 20px !important;
  }
}

.startHereContainer {
  display: flex;
  position: absolute;
  top: 0;
  width: 1000px;
  justify-content: end;
}

.startHere {
  display: flex;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  padding: 6px;
  border-bottom-left-radius: var(--mediumBorderRadius);
  border-bottom-right-radius: var(--mediumBorderRadius);
  transition: background-color 0.2s;
}

.startHere:hover {
  background-color: rgba(50, 50, 50, 0.3);
}

.harleyBLogo {
  height: 64px;
}

.ktmBLogo {
  height: 42px;
}

.triumphBLogo {
  height: 48px;
}

.BannerLogo {
  max-height: 120px;
  max-width: 240px;
  object-fit: contain;
}

.BannerLogoLarge {
  max-height: 360px;
  max-width: 480px;
  object-fit: contain;
}

.bannerVehicleSelectContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bannerBgImg {
  object-fit: cover;
}

.searchButtonSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 36px;
  flex-grow: 0;
  flex-shrink: 0;
}

.selectedVehicleHomeBanner {
  display: flex;
  cursor: pointer;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  /* background-color: var(--mainColor); */
  color: white;
  gap: 10px;
  align-items: center;
  width: fit-content;
  margin-inline: 10px;
  border-radius: var(--mediumBorderRadius);
  transition: background-color 0.2s;
}

.selectedVehicleHomeBanner:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: var(--mainColorHover); */
}

@media (max-width: 1280px) {
  .bannerContainer {
    gap: 10px;
  }
  .bannerContainer, .defaultBannerBG {
    padding: 0;
    height: 75vh;
    max-height: 600px;
    .brandLogosBanner {
      width: 48px;
    }
    .brandLogosBannerWide {
      width: 90px;
    }
  }
  .startHereContainer {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .BannerLogo {
    max-height: 80px;
    max-width: 180px;
  }
  .BannerLogoLarge {
    max-height: 210px;
    max-width: 360px;
    padding-inline: 20px;
    box-sizing: border-box;
  }
  .harleyBLogo {
    height: 36px;
  }
  
  .ktmBLogo {
    height: 28px;
  }
  
  .triumphBLogo {
    height: 28px;
  }
  .startHereContainer {
    width: 80%;
  }
  .bannerRowBrands {
    .headerFilterBrandLogo {
      width: 64px;
      height: 64px;
    }
  }
  .bannerHighlightText {
    font-size: 16px;
  }
  .bannerHighlightTextLarge {
    font-size: 18px;
  }
  .titleContainer {
    h1 {
      font-size: 16px !important;
    }
  }
}