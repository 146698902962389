@import '../Style/Variables.css';

h3 {
  margin: 0;
}

.orderRow {
  border-radius: var(--smallBorderRadius);
  border: 1px solid var(--lightGray);
  margin-bottom: 10px;
}

.orderRow:last-child {
  margin-bottom: 0;
}

.orderHeader {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.orderDetails {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid var(--lightGray);
}

.orderDetails:first-child {
  border-top: none;
}

.orderDetailsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.orderDetailsRow:last-child {
  margin-bottom: 0;
}

.orderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  /* border-top: 1px solid var(--lightGray); */
  /* background-color: var(--veryVeryLightGray); */
}

.orderHeaderProducts {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 !important
  /* flex-wrap: wrap; */
  /* gap: 10px; */
}

.orderHeaderProductContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 4px 8px 4px 4px !important;
  border-bottom: 1px solid var(--lightGray);
}

.orderHeaderProductContainer:last-child {
  border-bottom: none;
}

.orderHeaderProductContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.orderHeaderImg {
  width: 56px;
  height: 56px;
  border-radius: var(--smallBorderRadius);
  object-fit: contain;
  background-color: white;
}

.orderHeaderProductTitle {
  font-size: 15px !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.orderHeaderProductTitle2 {
  font-size: 15px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 32vw;
}

.timelineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 10px;
  margin-bottom: 10px;
  border-radius: var(--smallBorderRadius);
  background-color: var(--veryLightGray);
  overflow: auto;
}

.colorLine {
  border-top: 1px solid lightgray;
  width: 2vw;
  margin-inline: 10px;
}

.pixQrCode {
  max-height: 280px;
  object-fit: contain;
}

.pixCode {
  text-align: center;
  font-size: 12px;
  overflow-wrap: break-word;
  word-break: break-all;
  background-color: var(--veryLightGray);
  border-radius: var(--smallBorderRadius);
  padding: 10px;
  max-width: 90vw;
}

.orderStatusHighlight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: var(--smallBorderRadius);
  /* color: white; */
}

.reviewStar {
  cursor: pointer;
  color: var(--mainColor);
  transition: color 0.2s ease;
  font-size: 32px !important;
}

.reviewStar:hover {
  color: var(--secondColor);
}

.selectedStar {
  color: var(--secondColor);
}

.reviewStarStatic {
  color: var(--secondColor) !important;
  font-size: 32px !important;
}

.reviewTextField {
  display: block;
  width: 400px;
  resize: none;
  overflow: hidden;
  padding: 10px;
}

.reviewTextDisplay {
  display: flex;
  padding: 6px;
  background-color: var(--veryVeryLightGray);
  border-radius: var(--smallBorderRadius);
}

@media (max-width: 800px) {
  /* .actionsContainer {
    justify-content: start;
  } */
  .pixQrCode {
    max-height: 200px;
  }
}

@media (max-width: 500px) {
  .orderHeaderImg {
    width: 42px;
    height: 42px;
  }
  .orderHeader {
    flex-direction: column;
    align-items: start;
    h3, p, span {
      font-size: 13px !important;
    }
  }
  .orderDetailsRow {
    flex-direction: column;
    span {
      text-align: start !important;
    }
  }
  .reviewTextField {
    width: 80vw;
  }
}