@import '../Style/Variables.css';

h3 {
    font-size: 16px !important;
}

h4 {
    font-size: 14px !important;
}

p {
    font-size: 14px;
    line-height: 1.2 !important;
}

.checkoutItemInfo {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    /* border-left: 1px solid var(--veryLightGray); */
    padding-left: 10px;
}

.checkoutSummary {
    display: flex;
    flex-direction: column;
    width: 640px;
    height: 100%;
    gap: 10px;
    span, a {
        font-size: 15px;
    }
    /* z-index: 0; */
}

.checkoutStepHeader:hover {
    a {
        color: var(--mainColor)
    }
}

.cardRowContainer {
    display: flex;
    flex-direction: row;
    gap: var(--mediumRowGap);
    span {
        font-size: 15px;
    }
}

.cardFormContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.cardShape {
    display: flex;
    flex-direction: column;
    padding: 16px 6px;
    border-radius: 12px;
    gap: var(--mediumRowGap);
    background-color: var(--veryLightGray);
    /* background-color: var(--mainColor); */
    /* color: white; */
    font-weight: 300;
    span {
        /* font-size: 13px; */
        padding-left: 10px;
    }
    input {
        margin: 0;
        margin-bottom: 6px;
        background-color: transparent;
        color: var(--textColor);
        /* font-size: 16px; */
        padding-top: 20px;
        padding-bottom: 20px;
    }
    input:last-child {
        margin-bottom: 0;
    }
    input::placeholder {
        /* color: white; */
        opacity: 0.7;
    }
    input:focus {
        outline: none;
        border: 1px solid lightgray;
    }
}

.cardColor {
    background-color: var(--mainColor);
    /* background-color: rgb(24, 24, 24); */
    color: white;
    input {
        border: 1px solid transparent;
        background-color: transparent;
        /* background-color: rgba(0, 0, 0, 0.05) !important; */
        color: white;
    }
    input::placeholder {
        color: white;
        opacity: 0.5;
    }
    input:focus {
        outline: none;
        /* border: 1px solid var(--transparentWhite); */
    }
}

.cardWarning {
    display: flex;
    flex: 1;
}

.antifraudContainer {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: end;
    padding: 10px;
    border-radius: var(--mediumBorderRadius);
    background-color: rgba(0, 0, 0, 0.2);
    height: fit-content;
    img {
        width: 48px;
        height: 48px;
    }
    h3 {
        font-size: 14px !important;
        font-weight: 600;
        line-height: 1.2
    }
    span {
        padding: 0 !important;
        line-height: 1.2;
        opacity: 0.5;
        font-size: 10px;
    }
}

.summaryProductList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lightGray);
    mark {
        background-color: var(--lightHighlightMark);
        color: var(--textColor);
        border-radius: var(--smallBorderRadius);
        padding: 0 6px;
        font-weight: 600;
    }
}

.summaryProductList:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

@media (max-width: 1280px) {
    .checkoutSummary {
        position: fixed;
        padding-bottom: 10px;
        height: fit-content;
        bottom: 0%;
        width: 100%;
        box-shadow: 0 -6px 18px rgba(0, 0, 0, 0.2);
        /* z-index: 1; */
    }
    /* .checkoutSummary {
        position: fixed;
        padding-bottom: 10px;
        height: fit-content;
        bottom: 0%;
        width: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    } */
}

@media (max-width: 800px) {
    .cardRowContainer {
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    .antifraudContainer {
        padding: 8px;
        img {
            width: 40px;
            height: 40px;
        }
        h3 {
            font-size: 12px !important;
        }
    }
}
@media (max-width: 360px) {
    .antifraudContainer {
        padding: 6px;
        img {
            width: 24px;
            height: 24px;
        }
        h3 {
            font-size: 11px !important;
        }
    }
}