@import '../../Style/Variables.css';

.swiper-button-next, .swiper-button-prev {
    color: var(--secondColor) !important;
    transition: color 0.2s;
    transform: scale(0.7);
    padding: 10px;
    border-radius: var(--smallBorderRadius);
    transition: background-color 0.3s;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    color: var(--mainColorHover);
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: rgba(1, 97, 255, 0.07); */
}

.swiper-button-disabled {
    display: none !important;
    /* cursor: default;
    color: var(--veryLightGray);
    background-color: var(--lightGray);
    opacity: 1 !important; */
}

.swiper-pagination-bullet-active {
    background-color: var(--secondColor) !important;
}

.productSliderSwiper {
    padding-bottom: 30px;
}