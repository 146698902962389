@import '../../Style/Variables.css';

.catTileContainer {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: var(--smallBorderRadius);
    height: var(--catTileHeight);
    width: var(--catTileWidth);
    padding: 10px;
    gap: 10px;
    overflow: hidden;
    margin: var(--catTileMargin);
    h2 {
        text-align: center;
    }

}

.smallerContainer {
    border-radius: var(--mediumBorderRadius);
    height: 180px;
    width: 180px;
    gap: 4px;
    h2 {
        font-size: 14px;
        line-height: 1.3;
    }
}

.catImg {
    width: 160px;
    transition: transform 0.2s;
}

.catImgSmall {
    width: 110px;
    transition: transform 0.2s;
}

.catTileContainer:hover {
    .catImg, .catImgSmall {
        transform: scale(1.1);
    }
}

@media (max-width: 500px) {
    .catTileContainer {
        width: 100%;
        flex-direction: row;
        justify-content: start;
        height: fit-content;
        margin: 0;
        h2 {
            text-align: start;
            font-size: 16px;
        }
        .catImg {
            width: 48px;
        }
        .catImgSmall {
            width: 48px;
        }
    }
}