@import '../Style/Variables.css';

.homeStyle {
  gap: 20px;
  padding-bottom: 20px;
  background-color: var(--lightGray);
  h1 {
    font-weight: 700;
    line-height: 1.2;
  }
}

.roadSection {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.staticBannerContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 480px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-90px);
}

.stepsBannerRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.bannerStepsImg {
  height: 120px;
  width: 120px;
  background-color: white;
  object-fit: contain;
  border-radius: var(--smallBorderRadius);
}

.catContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  img {
    transition: transform 0.2s ease;
  }
}

.UHDcatContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: start;
  justify-content: end;
  position: relative; /* Ensure the pseudo-element is positioned correctly */
}

.UHDcatContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for the opacity */
  z-index: 1; /* Ensure it is above the background but below the content */
}

.UHDcatContainer:hover::before {
  opacity: 1; /* Show the darkening effect on hover */
}

.catText {
  position: relative; /* Ensure text is above the pseudo-element */
  z-index: 2; /* Ensure text is above the dark overlay */
}

.UhdOfferWide, .UhdOfferNarrow {
  overflow: hidden;
}

.umuaramaHDofferContainer {
  max-width: 1280px;
  width: 100%;
  justify-content: start;
  overflow: hidden;
  padding-inline: 0 !important;
}

.largeOfferContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 40px;
  gap: 6px;
}

.smallOfferContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 33%;
  left: 33px;
  gap: 6px;
}

/* .UHDofferContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  height: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: start;
  justify-content: end;
}

@media (min-width: 1281px) {
  .UHDofferContainer:first-child {
    flex: 1.42;
  }
  .UHDofferContainer:nth-child(2) {
    flex: 1;
  }
} */

.catContainer:hover {
  img {
    transform: scale(1.05);
  }
}

.catImgHome {
  display: flex;
  flex: 2;
  width: 160px;
}

.catText {
  display: flex;
  flex-direction: column;
  flex: 3;
  color: white;
  align-items: start;
  gap: 10px;
  h1 {
    font-size: 20px;
  }
}

.wideShort {
  max-width: 1280px;
  height: 300px;
}

.responsiveWide {
  display: flex;
  width: 500px;
  /* background-color: lightcoral; */
}

.bannerVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.homeSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: var(--lightGray);
}
.noGapMobile {
  display: flex;
}

.catHomePadding {
  padding-top: 0;
}

.homeSectonsBorder {
  border-radius: var(--mediumBorderRadius);
  overflow: hidden;
  /* background-color: lightgreen; */
}

.homeHighlightRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1280px;
  margin: auto;
  /* padding: 20px 0; */
  gap: 10px;
  height: fit-content;
  align-items: start;
  justify-content: space-between;
}

.homeHighlightItem {
  min-height: 80px;
  min-width: 120px;
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 10px;
  background-color: white;
  border-radius: var(--mediumBorderRadius);
  font-size: 14px;
}

.storesHighlightItem {
  cursor: pointer;
  min-height: 80px;
  min-width: 120px;
  height: 120px;
  /* height: 140px; */
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: var(--mediumBorderRadius);
  font-size: 14px;
  img {
    max-height: 80%;
    max-width: 80%;
    transition: transform 0.2s ease;
  }
}

.storesHighlightItem:hover {
  img {
    transform: scale(1.05);
  }
}

.highlightIcon {
  color: var(--secondColor);
  font-size: 32px;
}

.reviewContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  height: 150px;
  /* background-color: #00a2b7; */
  border-radius: var(--mediumBorderRadius);
}

.paymentContainer {
  width: 1280px;
  min-height: 320px;
}

.cardOpImg {
  width: 64px;
}

.payImg {
  width: 120px;
  height: 60px;
}

.payRowLeft {
  flex: 3;
  width: 100%;
  align-items: end;
  padding: 20px 20px 0 0;
  background-color: var(--veryLightGray) !important;
}

.payRowRight {
  flex: 6;
  width: 100%;
  align-items: start;
  padding: 20px;
  padding-right: 80px;
  background-color: var(--veryLightGray) !important;
}

/* .homeHighlightItem {
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid var(--secondColor);
  padding: 10px;
} */

/* .homeHighlightItem:last-child {
  border-right: none;
} */

.centerGridHome {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  padding: 0;
}

.brandLogos {
  width: 64px;
}

.paymentImg {
  width: 240px
}

.paymentImgMobile {
  display: none;
  max-height: 180px;
  max-width: 120px;
}

.reclameImg {
  width: 48px;
  /* width: 120px; */
}

.reclameLogo {
  width: 175px;
  padding-bottom: 20px;
}

.googleLogo {
  width: 175px;
  padding-bottom: 10px;
}

.reclameContainer {
  width: 100%;
  min-height: 240px;
  flex: 1;
  padding-inline: 20px;
  background-color: white;
  /* background-color: #007A3E; */
  mark {
    background-color: #96C126;
  }
  a {
    color: #05642F;
  }
}

.reclameMark {
  /* cursor: pointer; */
  color: var(--textColor);
  mark {
    background-color: #96C126;
    transition: background-color 0.2s ease;
  }
  img {
    transition: transform 0.2s ease;
  }
}

/* .reclameMark:hover {
  mark {
    background-color: #b1db48;
  }
  img {
    transform: scale(1.05);
  }
} */

.googleMark {
  cursor: pointer;
  color: black;
  mark {
    background-color: white;
    color: black;
  }
  .googleStar {
    font-size: 28px;
    color: orange;
  }
  .googleLogo {
    width: 125px;
    padding-bottom: 6px;
  }
}

.whatsappMark {
  cursor: pointer;
  gap: 20px;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  span, h1 {
    color: white;
  }
}

.GoParcaShowContainer {
  display: flex;
  height: 100%;
  align-items: end;
  /* background-color: lightcoral; */
}

.oilContainer {
  flex: 1;
  width: '100%';
  min-height: '360px' !important;
  background-image: linear-gradient(to top right, #0c7684, #00a2b7);
}

.oilImg {
  width: 180px;
}

.engineContainer {
  flex: 1;
  width: '100%';
  min-height: '360px' !important;
  background-image: linear-gradient(to top right, #be122f, #FF5773);
}

.engineImg {
  width: 180px;
}

.googleContainer {
  width: 100%;
  min-height: 240px;
  flex: 1;
  padding-inline: 20px;
  background-color: #0161FF;
  color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  mark {
    background-color: white;
  }
  a {
    color: white !important;
  }
}

.rowToColumnNoGap {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
}

.rowToColumnGap {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
}

.brandsContainer {
  max-width: 1280px;
  color: white;
  min-height: 320px;
  padding: 0;
}

.GoParcaSearch {
  height: 200px;
  padding-inline-end: 20px;
}

.GoParcaShowDesktop {
  display: flex;
  height: 170px;
  align-self: end;
}

.GoParcaShowMobile {
  display: none;
  height: 170px;
  align-self: end;
}

.whatsappSectionContainer {
  flex-direction: row;
  color: white;
  width: 100%;
  max-width: 1280px;
  min-height: 240px;
  background-image: linear-gradient(to right, #075E54, #25D366);
  gap: 20px
}

.whatsappCtaContainer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  /* padding: 10px; */
  padding-inline: 10px;
  border-radius: var(--mediumBorderRadius);
  /* color: var(--textColor); */
  color: white;
  align-items: end;
  gap: 10px;
  width: fit-content;
  .mobileSpan {
    display: none;
    max-width: 100px;
    margin-left: 6px;
  }
  .deskSpan {
    display: flex;
  }
}

@media (max-width: 1280px) {
  .centerGridHome {
    padding: 0;
  }
  .homeStyle {
    gap: 0;
    padding-bottom: 0;
  }
  .paymentContainer {
    width: 100%;
    height: fit-content;
    align-items: center;
  }
  .brakesImg01 {
    max-width: 420px
  }
  .rowToColumnNoGap {
    flex-direction: column !important;
    gap: 0;
  }
  .rowToColumnGap {
    flex-direction: column !important;
    gap: 20px;
  }
  .homeSectonsBorder {
    border-radius: 0;
  }
  .responsiveRow {
    flex-direction: column;
    margin-inline: 20px;
  }
  .homeSectionContainer {
    padding-inline: 20px;
  }
  .noGapMobile {
    gap: 0;
  }
  .catHomePadding {
    padding-top: 20px;
  }
  .payRowLeft {
    align-items: center;
    justify-content: end;
    min-height: 0;
    height: fit-content;
  }
  .homeHighlightRow {
    width: auto;
    padding: 20px;
  }
  .payRowRight {
    /* align-items: center; */
    justify-content: start;
    height: fit-content;
    padding-bottom: 20px;
    padding-inline: 20px;
    width: auto;
    /* text-align: center; */
  }
  .reclameMark, .googleMark {
    padding: 10px 0;
  }
  .whatsappMark {
    gap: 6px;
  }
  .GoParcaSearch {
    height: 140px;
  }
  .paymentImg {
    width: 180px;
  }
  .reviewContainer {
    width: 100%;
  }
  .reclameLogo {
    padding-bottom: 10px;
  }
  .staticBannerContainer {
    height: 500px;
  }
  .UHDcatContainer {
    height: 120px;
  }
  .UhdOfferWide, .UhdOfferNarrow {
    width: 100vw;
  }
  .umuaramaHDofferContainer {
    overflow: visible;
  }
  .largeOfferContainer, .smallOfferContainer {
    left: 5%;
    h1 {
      font-size: 22px !important;
    }
  }
}

@media (max-width: 800px) {
  .whatsappCtaContainer {
    flex-direction: column-reverse;
    align-items: start;
    gap: 0;
    .mobileSpan {
      display: flex;
    }
    .deskSpan {
      display: none;
    }
  }
  .wideShort {
    height: fit-content;
  }
  .brakesImg01 {
    width: calc(100vw - 60px);
  }
  .catImgHome {
    max-width: 100px;
  }
  .googleContainer, .reclameContainer {
    min-height: 100px;
  }
  .paymentImg {
    display: none;
  }
  .paymentImgMobile {
    display: flex;
  }
  .reclameImg {
    width: 36px;
  }
  .GoParcaShow {
    height: 64px;
  }
  .whatsappMark {
    max-height: 120px;
    span {
      font-size: 12px;
    }
  }
  .GoParcaShowDesktop {
    display: none;
  }
  .GoParcaShowMobile {
    display: flex;
  }
  .stepsBannerRow {
    gap: 0;
  }
  .bannerStepsImg {
    height: 90px;
    width: 90px;
  }
  .UHDofferContainer {
    display: block;
  }
  .UHDofferContainer:first-child {
    width: 100vw;
    height: 48vw;
    background-size: contain;
  }
  .UHDofferContainer:nth-child(2) {
    width: 100vw;
    height: 72vw;
    background-size: contain;
  }
  .largeOfferContainer, .smallOfferContainer {
    h1 {
      font-size: 18px !important;
    }
  }
}

@media (max-width: 500px) {
  .whatsappSectionContainer {
    gap: 0;
    height: fit-content !important;
  }
  .GoParcaSearch {
    height: 100px;
  }
  .reclameLogo {
    width: 125px;
  }  
  .responsiveWide {
    width: 100%;
  }
  .responsiveRow500 {
    flex-direction: column;
  }
  .cardOpImg {
    width: 48px;
  }
  .payImg {
    width: 84px;
    height: 42px;
  }
  .brandsContainer {
    padding: 10px;
  }
}