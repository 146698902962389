@import '../Style/Variables.css';


.vehicleSelectContainer {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
    /* max-height: calc(100% - var(--headerHeight)); */
    max-height: 100vh;
    overflow-x: auto;
    padding: 10px 0;
    /* margin-top: var(--headerHeight); */
    background-color: var(--mainColor);
    z-index: 12;
    transform: translateY(0);
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.3);
    .selected {
        justify-content: end;
        transform: translateX(20px);
        padding: 8px 8px 0 0 !important;
    }
}

.vehicleSelectContainer::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

/* Hide scrollbar for IE, Edge, and Firefox */
.vehicleSelectContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
}

.bg {
    transform: translateY(0);
    transition: transform 0.2s ease-in-out;
}

.noBg {
    transform: translateY(-100px);
    transition: transform 0.2s ease-in-out;
    background-color: transparent !important;
    box-shadow: none;
}

.noBgspacing {
    height: var(--headerHeight);
    width: 100vw;
    background-color: var(--mainColor);
}

.vehicleSelectionHeaderRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 20px;
    gap: 16px;
    justify-content: center;
}

.vehicleSelectLabel {
    color: white;
    margin-bottom: 10px;
}

.vehicleSelectRow {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    /* width: 1280px; */
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px;
    gap: 10px;
    transition: max-width 0.4s;
    select {
        width: 100%;
        height: 36px;
    }
    a {
        color: var(--mainContent);
    }
}

.selectLabelAnim {
    max-width: 130px;
    transition: max-width 0.2s ease;
    span {
        display: none;
    };
}

.selectLabelAnim:hover {
    max-width: 400px;
    span {
        display: block;
    };
    .selectedImagesContainer {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    };
}

.vehicleFilterItem {
    display: flex;
    img {
        width: 100px;
    }
}

.selectedModelImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 4px 8px;
    height: 100%;
    height: 36px;
    background-color: white;
}

.selectedVehicleRow {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 36px;
    /* padding: 0 18px 0 6px; */
    background-color: var(--mainColor);
    /* background-color: white; */
    border-radius: var(--smallBorderRadius);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    /* transform: translateX(20px); */
    span {
        color: var(--mainContent);
        margin-right: 10px;
    }
    .modelSelectorImg {
        background-color: transparent;
        transform: scale(var(--scaleHover))
    }
    .selectedModelImg {
        background-color: white;
    }
}

.selectedVehicleRow:hover {
    background-color: var(--mainColorHover);
    .smallButtonCorner {
        background-color: var(--mainColor);
        color: var(--mainContent)
    }
}

.selectedImagesContainer {
    display: flex;
    height: 36px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-inline: 6px;
    gap: 10px;
    border-radius: var(--smallBorderRadius);
}

.selectedOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: var(--smallBorderRadius);
    background-color: white;
    span {
        padding-inline: 4px 10px;
    }
}

.selectedModelImg {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: var(--smallBorderRadius);
    background-color: white;
    height: 24px;
}

.vehicleSelectorImg {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: var(--smallBorderRadius);
    background-color: white;
}

.selectedBrandImgContainer {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding-inline: 8px 4px;
    border-radius: var(--smallBorderRadius);
    background-color: white;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.selectedBrandImg {
    width: 28px;
    height: 28px;
    background-color: transparent !important;
    overflow: hidden;
}

.modelSelectorImg {
    height: 32px;
    border-radius: var(--smallBorderRadius);
    background-color: white;
    padding-inline: 6px 4px;
}

.resetSelection:hover {
    .smallButtonCorner {
        background-color: var(--mainColor);
        color: var(--mainContent)
    }
}

.selectedVehicleTitle {
    max-width: 240px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: var(--mainContent);
}

.halfColumn {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

@media (max-width: 1366px) {
    .vehicleSelectContainer {
        .selected {
            transform: translateX(0);
        }
    }
}

@media (max-width: 960px) {
    .closeRightAlign {
        position: relative;
        margin-right: 10px;
    }
}

@media (max-width: 800px) {
    .vehicleSelectRow {
        flex-wrap: wrap;
    }
}
@media (max-width: 600px) {
    .vehicleSelectRow {
        .buttonLabelAnim {
            max-width: fit-content;
            span {
                display: block;
            }
        }
    }
    .vehicleSelectContainer {
        max-height: calc(100% - var(--headerHeightMobile));
    }

    .selectLabelAnim {
        max-width: 360px;
        span {
            display: block;
        };
        .selectedImagesContainer {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        };
    }
}

@media (max-width: 400px) {
    .selectLabelAnim {
        max-width: 80vw;
    }
}